body {
  background: #eeeeee;
  --main-color: #1b007b;
  --second-color: #fc0071;
}

* {
  box-sizing: border-box;
  font-family: 'Encode Sans Expanded', sans-serif;
}

/*  NAVBAR  */
    .nav {
      background: transparent ;
      height: 80px;
      margin-top: -80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      position: sticky;
      top: 0;
      z-index: 10;
      transition: all 0.2s ease;
    }
    .nav-transparent {
      background: var(--main-color)
    }
    .nav-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      z-index: 1;
      width: 100%;
      padding: 0 24px;
    }
    .nav-link-router {
      color: #fff;
      justify-self: flex-start;
      cursor: pointer;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      margin-left: 24px;
      font-weight: bold;
      text-decoration: none;   
    }
    .nav-logo {
      width: 30%;
    }
    .nav-div-icon-menu {
      display: none;
    }
    .nav-ul {
      display: flex;
      width: 100%;
      text-align: center;
      justify-content: flex-end;
      margin-right: 7%;
      margin-bottom: 0;
    }
    .nav-li {
      height: 80px;
      list-style: none;
    }
    .nav-li-links {
      color: #fff ;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0 1rem;
      height: 100%;
      cursor: pointer;
    }    
    .nav-li-links.active {
      border-bottom: 3px solid var(--second-color) ;
    }    
    .nav-li-links:hover {
      color: var(--second-color) ;
    }
    .nav-link-instagram {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--second-color);
      border-radius: 10px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
    .nav-link-instagram:hover {
      background: #CD1467;
    }
    .nav-instagram-icon {
      font-size: 25px;
      color: white;
    }
    .nav-instagram-icon:hover {
      color: #DAD9D9;        
    }
/*  END OF NAVBAR  */


/*  SIDEBAR  */
.sidebar-container {
  position: fixed;
  z-index: 999;
  width:100%;
  height: 100%;
  background: var(--main-color);
  display: grid;
  align-items: center;
  top: 0px;
  left: 0px;
  transition: opacity, top 0.3s ease-in-out;
  opacity: 0;
  top: -100%;
}
.sidebar-container-is-open {
  opacity: 1;
  top: 0 !important;
}
.sidebar-wrapper-icon-close {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none; 
}
.sidebar-icon-close {
  color: #fff;
}
.sidebar-icon-close:hover {
  color: var(--second-color);
}
.sidebar-wrapper {
  color: #fff;
  margin: 0;  
}
.sidebar-menu-ul {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6,80px);
  text-align: center;
  margin-right: 30px;
}
.sidebar-link-scroll {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2 ease-in-out;
  color: #fff;
  cursor: pointer;
}
.sidebar-link-scroll:hover {
  color: var(--second-color);
  transition: 0.2 ease-in-out;
}
.sidebar-wrapper-button {
  display: flex;
  justify-content: center;
}
.sidebar-button {
  border-radius: 50px;
  background: var(--second-color);
  white-space: nowrap;
  padding: 12px 64px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
.sidebar-button:hover {
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: var(--second-color);
}
/*  END OF SIDEBAR  */



/*  HOME SECTION */
    .home-container {
      background: #0c0c0c;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 30px;
      height: 100vh;
      position: relative;
      z-index: 1;
    }
    .home-background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
    .home-image-background {
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      background: #232a34;
      filter:brightness(0.6);
    }
    .home-content {
      z-index: 3;
      max-width: 1200px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20%;
    }
    .home-up-title {
      color: var(--second-color);
      font-size: 20px;
      text-align: center;
      font-weight: 700;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      margin-bottom: 4px;
    }
    .home-title {
      margin: 0;
      padding: 0;
      color: #fff;
      font-size: 70px;
      text-align: center;
      font-family: 'Passion One', cursive;
      z-index: 99;
    }
    .home-button {
      border-radius: 50px;
      background: var(--second-color);
      padding: 12px 40px;
      margin-top: 30px;
      color: white;
      font-size: 20px;
      outline: none;
      border: none;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
    }
    .home-button:hover {
      background: #1B0071;
      color: white;
    }
    .home-background-mobile {
      display: none;
    }
/*  END OF HOME SECTION */


/*  ESTILOS GENERALES PARA LAS CASAS DE 3 Y 5 PERSONAS  */
.casa-container {
  height: 100vh;
  /* background: var(--main-color); */

}
.casa-row {
  width: 100%;
  height: 100vh;
  margin: 0;
}
.row {
  overflow-y:inherit
}
.casa-column-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 0;

}
.casa-column-1-wrapper {
  padding: 15px;
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-root{
  width: 100%;
  max-width: 650px;
  padding-left: 10px;
}
.carousel .control-dots {
  display: none;
}
.carousel .control-prev.control-arrow {
  background-color:#acacac;
  opacity: 0.4;
} 
.carousel .control-next.control-arrow {
  background-color:#acacac;
  opacity: 0.4;
}
.casa-column-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}
.casa-column-2-items-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.casa-column-2-title-wrap {
  width: 100%;
}
.casa-column-2-up-title {
  color: var(--second-color);
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-left: 20px;
}
.casa-column-2-item {
  display: flex;
  justify-content:flex-start;
  color: #787976;
  width: 80%;
  margin-left: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.casa-column-2-item:hover {
  color: var(--second-color);
}
.casa-column-2-icon {
  font-size: 20px;
  margin-right: 10px;
} 

/*  END OF ESTILOS GENERALES SECTION  */


/*  CASA GRANDE SECTION  */
.casa-grande {
  background: var(--main-color);
}
.casa-grande-font-color {
  color: white;
}
/*  END OF CASA GRANDE SECTION  */

/* UBICATION SECTION */
.ubication-container {
  height: 100vh;
  background: var(--main-color);
  display: flex;
  justify-content: center;
}
.ubication-row {
  width: 100%;
  height: 100vh;
  margin: 0;
}
.row {
  overflow-y:inherit
}
.ubication-wrapper {
  margin-top: 50px;
}
.ubication-up-title {
  color: var(--second-color);
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-top: 30px;
}
.ubication-link-google-maps {
  text-decoration: none;
  cursor: pointer;
}
.ubication-subtitle {
  color: #ffffff;
  align-items: center;
  display: flex;
}
.ubication-icon {
  font-size: 25px;
  color: var(--second-color);
}
.ubication-map-border {
  border: #ececec solid 2px;
}
/* END OF UBICATION SECTION */

/* EXPERIENCES SECTION */
    .experiences-container {
      height: 950px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #eeeeee;
    }
    .experiences-wrapper {
      max-width: 1000px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      grid-gap: 16px;
    }
    .experiences-up-title-section {
      color: var(--second-color);
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
    .experiences-title-section {
      font-size: 2.5rem;
      color: #484a46;
      margin-bottom: 64px;
      padding: 0px 20px 0px 20px ;
      text-align: center; 
      font-weight: 600;
    }
    .experiences-card {
      background-size: cover;
      overflow: hidden;
      display: flex;
      justify-content: center;
      border-radius: 5px;
      max-height: 340px;
      padding: 10rem 0 0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      transition: all 0.2s ease-in-out;
      width: 230px;
      height: 230px;
      text-align: center;
      transition: transform 500ms ease;
    }
     .experiences-card:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
    .experiences-title-card {
      font-size: 1.5rem;
      margin-bottom: 10px;
      color: #fff;
      transition: 300ms ease;
      transform: translateX(0);
      text-shadow: 1px 1px #000;
    }
    .experiences-title-card:hover {
      padding-bottom: 10px;
      border-bottom: 3px solid var(--second-color);
      transform: translateX(5%);
    }
/* END OF EXPERIENCES SECTION */



/* CONTACT SECTION */
    .contact-container {
      background: #eeeeee;
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .contact-column-1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .contact-row {
      width: 100%;
    }
    .contact-column-2 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .contact-up-title-section {
      color: var(--second-color);
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    .contact-title-section {
      margin-bottom: 15px;
      font-size: 48px;
      line-height: 1.1;
      font-weight: 600;
      color: #484a46;
    }
    .contact-text {
      max-width: 440px;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 24px;
      color: #787976;
    }
    .contact-text-info {
      max-width: 440px;
      font-size: 16px;
      color: #787976;
      line-height: 2;
      
    }
    .contact-icon {
      color: var(--second-color);  
      margin-right: 6px;
    }
    .contact-text-highlight {
      color: var(--second-color);
    }
    .contact-form {
      width: 90%;
      height: auto;
      z-index: 1;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 0;
      margin-left: 40px;
    }
    .contact-input {
      padding: 10px 12px;
      margin-bottom: 10px;
      border: none;
      border-radius: 4px;
      width: 100%;
      background: white;
      color: gray;
    }
    .contact-input:focus {
      outline: none;
      border: 2px solid var(--second-color);
    }
    .contact-textarea {
      padding: 16px 16px;
      margin-bottom: 10px;
      border: none;
      border-radius: 4px;
    }
    .contact-textarea:focus {
      outline: none;
      border: 2px solid var(--second-color);
    }
    .contact-button {
      background: var(--second-color);
      padding: 8px 0px;
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      width: 100%;
    }
    .contact-button:hover {
      background: transparent;
      border: 2px solid var(--second-color) ;
      color: var(--second-color);
    }
    .contact-error {
      text-align: center;
      margin-top: 7px;
      color: red;
      font-size: 12px;
    }
    .contact-wrapper-img-email {
      margin-left: 30%;
    }
    .contact-img-email-sent {
      width: 40%;
    }
    .contact-text-email-sent {
      color: var(--second-color);
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      margin-top: 10px;
    } 
    .contact-display-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .contact-text-entry {
      margin-right: 50px;
    }
    .contact-text-departure {
      margin-right: 66px;
    }
        /* Cambio de estilos en Datepicker */
        .MuiFormControl-marginNormal {
          margin: 0 !important;
          width: 100%;
        }
        .MuiInputBase-input {
          color: gray !important;
          padding: 0 !important;
          text-align: end !important;
        }
        .MuiInputLabel-shrink {
          font-size: 17px !important;
          color: #757575 !important;
        }
        .MuiInput-underline:before {
          border-bottom: none !important;
        }
/* END OF CONTACT SECTION */


/* FOOTER */
    .footer-container {
      background-color: #101522;
    }
    .footer-wrapper {
      padding: 48px 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 1100px;
      margin: 0 auto;
    }
    .footer-links-container {
      display: flex;
      justify-content: space-between;
      max-width: 1100px;
      width: 100%;
      padding-left: 20%;
      padding-right: 20%;
    }
    .footer-link {
      color: #fff;
      text-decoration: none;
      margin-bottom: 0.5rem;
      font-size: 14px;
      cursor: pointer;
    }
    .footer-link:hover {
      color: var(--second-color);
      transition: 0ms.3 ease-in-out;
    }
    .footer-social-media-section {
      max-width: 1000px;
      width: 100%;
    }
    .footer-social-media-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1100px;
      margin: 40px auto 0 auto;
    }
    .footer-link-logo {
      display: flex;
      justify-self: start;
      align-items: center;
      cursor: pointer;
    }
    .footer-logo {
      width: 20%;
    }
    .footer-website-rights {
      color: #fff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin-right: 10px;
    }
    .footer-wrapper-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 240px;
    }
    .footer-link-icon {
      color: #fff;
      font-size: 24px;
      margin-right: 10px;
      margin-left: 10px;
    }
    .footer-link-icon:hover {
      color: var(--second-color)
    }
/* END OF FOOTER */


/*  WHATSAPP  */
    .whatsapp-link {
      position:fixed;
      width:3rem;
      height:3rem;
      bottom:1rem;
      right:1rem;
      background-color:#25d366;
      color:#FFF;
      border-radius:50px;
      text-align:center;
      font-size:30px;
      z-index:9;
	    align-items: flex-end;	
      display: flex;
      justify-content: center;
      padding-bottom: 8px;
    }
    .whatsapp-link:hover {
      text-decoration: none;
      color: #25d366;
      background-color:#fff;
    }
    .whatsapp-icon {
      margin-top: 8px;
    }
/*  END OF WHATSAPP  */








@media screen and (max-width: 1000px) {
  /* EXPERIENCES SECTION 1000px */
  .experiences-container{
    height: 1200px;
  }
  .experiences-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  } 
}




@media screen and (max-width: 768px) {

  /* NAVBAR  768px*/
  .nav-div-icon-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 40%);
    font-size: 1.8rem;
    cursor: pointer; 
    color: #fff;
  }
  .nav-ul {
    display: none;
  }
  .nav-link-instagram {
    display: none;
  }

  /* HOME SECTION 768px*/
  .home-title{
    font-size: 70px;
  }

  /* Casa Chica SECTION 768px*/
 .casa-container {
    height: auto;
    margin-top: 70px;
    margin-bottom: 30px;
  }
  .casa-row {
    display: contents;
  }
  .casa-column-1 {
    height: 60%;
    width: 100%;
  }
  .casa-column-1-wrapper {
    padding: 0;
    width: inherit;
  }
  .carousel-root{
    padding-left: 0;
  }
  .casa-column-2 {
    height: 40%;
    width: 100%;
  }
  .casa-column-2-title-wrap {
    text-align: center;
    width: auto;
  }
  .casa-column-2-up-title {
    font-size: 20px;
  }
  .casa-column-2-item {
    width: 80%;
    margin:5px 0px;
    justify-content: flex-start;
  } 
  

  
  /* CASA GRANDE SECTION 768px*/
  .casa-grande-container {
    height: auto;
    margin-top: 70px;
    margin-bottom: 30px;
    padding-top: 15px;
  }
  .casa-grande-row {
    display: contents;
  }
  .casa-grande-column-1 {
    height: 60%;
  }
  .casa-grande-column-1-wrapper {
    padding: 0;
    width: inherit;
  }
  .casa-grande-column-2 {
    height: 40%;
  }
  .casa-grande-column-2-title-wrap {
    text-align: center;
    width: auto;
  }
  .casa-grande-column-2-up-title {
    font-size: 20px;
  }
  .casa-grande-column-2-item {
    width: 80%;
    margin:5px 0px;
    justify-content: flex-start;
    
  }


  /* CONTACT SECTION 768px */
  .contact-container {
    padding: 100px 0;
  }
  .contact-form {
    margin-left: 0;
    width: 100%;
  }
  .contact-column-1 {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .contact-img-email-sent {
    width: 65%;  
  } 
  .MuiInputLabel-shrink {
    font-size: 20px !important;
  }

  /* UBICATION SECTION 768px*/
  .ubication-wrapper {
    margin-top: 0px;
  }

  /* FOOTER 768px*/
  .footer-links-container {
    padding-top: 32px;
    padding-left: 10%;
    padding-right: 10%;
  }
  .footer-social-media-wrapper {
    flex-direction: column;
  }
  .footer-link-logo {
    margin-bottom: 14px;
    justify-content: center;
  }
  .footer-website-rights {
    margin-bottom: 14px;
  }
  
}




@media screen and (max-width: 450px) {

  /* HOME 450px*/
  .home-title{
    font-size: 60px;
  }
  .home-background {
    display: none;
  }
  .home-background-mobile {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .home-image-background-mobile {
    object-fit: cover;
    background: #232a34;
    width: 100%;
  }
  .home-content {
    bottom: 20%
  }

  /* US 450px*/
  .us-container{
    display: flex;
    justify-content: center;
  } 
  .us-section{
    width: 100%;
  }
  .us-title {
    font-size: 1.5rem;
  }
  .us-wrapper-button{
    display: none;
  }
  .us-wrapper-info {
  padding: 30px 20px 0px 20px;
  }
  .us-video {
    height: 200px;
    margin-bottom: 50px;
  }
  .us-wrapper-video {
    margin-bottom: 30px;
  }



  /* CONTACT SECTION 450px */
  .conctact-row {
    height: 100vh;
    width: 100%;
  }
  
  .contact-title-section {
    font-size: 40px;
  }
  .contact-column-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
  }


  /* FOOTER 450px */
  .footer-links-container {
    padding-left: 0%;
    padding-right: 0%;
  }


  /* SIDEBAR 450px */
  .sidebar-menu-ul {
    grid-template-rows: repeat( 6, 80px );
  }
}
